import { IconCircleCheck } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SuperButton from './SuperButton';
import { useLocalStore, usePackages, useSettings } from '../hooks';
import { useUser } from '../hooks/auth';
import { useLoginRegister } from '../hooks/templates';
import SectionLoading from './SectionLoading';
import TablerIcon from './TablerIcon';
import { MotionDiv, MotionItem } from './animations';
import { payGoogle, shareBonus } from '../api';

export default function PricingCards() {
  const [isGooglePlaySupported, setIsGooglePlaySupported] = useLocalStore('googlePlayApp', false);
  const [isProcessingPayment, setProcessingPayment] = useState(false);
  const [playPrices, setPlayPrices] = useState({});
  const { isLoading, settings } = useSettings();
  const {
    isLoading: isPackagesLoading, packages,
  } = usePackages();

  useEffect(() => {
    const googlePlayBilling = async () => {
      if ('getDigitalGoodsService' in window) {
        try {
          await window.getDigitalGoodsService('https://play.google.com/billing');
          setIsGooglePlaySupported(true);
        } catch (error) {
          setIsGooglePlaySupported(false);
        }
      } else {
        setIsGooglePlaySupported(false);
      }
    };

    googlePlayBilling();
  }, []);

  useEffect(() => {
    const getPlayPrices = async () => {
      if (isGooglePlaySupported && packages && packages.length > 0) {
        const service = await window.getDigitalGoodsService('https://play.google.com/billing');
        const itemDetails = await service.getDetails(packages.map((pack) => pack.plan_name));
        // eslint-disable-next-line no-restricted-syntax
        for (const item of itemDetails) {
          const localPrice = new Intl.NumberFormat(navigator.language, {
            style: 'currency',
            currency: item.price.currency,
          }).format(item.price.value);

          setPlayPrices((prevState) => ({
            ...prevState,
            [item.itemId]: localPrice,
          }));
        }
      }
    };

    getPlayPrices();
  }, [packages, isGooglePlaySupported]);

  const handleGooglePaymentRequest = async (itemId) => {
    try {
      setProcessingPayment(true);
      const paymentMethodData = [
        {
          supportedMethods: 'https://play.google.com/billing',
          data: {
            sku: itemId,
          },
        },
      ];

      const request = new PaymentRequest(paymentMethodData);
      const paymentResponse = await request.show();
      const { purchaseToken } = paymentResponse.details;

      payGoogle({
        plan: itemId,
        token: purchaseToken,
      }).then(async (data) => {
        if (data?.errors) {
          await paymentResponse.complete('fail');
          toast.error('Płatność zakończona niepowodzeniem.');
          return;
        }

        await paymentResponse.complete('success');
        toast.success('Płatność przebiegła pomyślnie, dziękujemy. Twoje konto zostało doładowane.');
        window.location.href = '/thank-you';
      }).catch(() => {
        toast.error('Płatność zakończona niepowodzeniem.');
      }).finally(() => {
        setProcessingPayment(false);
      });
    } catch (error) {
      toast.error('Płatność zakończona niepowodzeniem.');
      setProcessingPayment(false);
    }
  };

  const sleep = (ms) => new Promise((resolve) => { setTimeout(resolve, ms); });

  const handleShare = async () => {
    try {
      shareBonus().then(async (data) => {
        if (data?.errors) {
          toast.error('Pakiet już odebrany.');
          return;
        }

        toast.success('Pakiet zostanie przypisany po potwierdzeniu udostępnienia. Przekierowanie...');
        await sleep(4000);
        window.location.href = 'https://www.facebook.com/sharer/sharer.php?u=https://rozwiazto.pl';
      }).catch(() => {
        toast.error('Pakiet już odebrany.');
      });
    } catch (error) {
      toast.error('Pakiet już odebrany.');
    }
  };

  const { user, isAuthenticated } = useUser();

  const {
    open, close, LoginRegisterModel,
  } = useLoginRegister();

  if (isLoading || !Object.keys(settings).length || isPackagesLoading) {
    return <SectionLoading center />;
  }

  return (
    <MotionDiv className="pricing-section" key={packages}>

      {!packages?.length && (
        <span>Brak pakietów do kupienia!</span>
      )}
      {packages?.map((pack) => (
        <MotionItem
          className={pack.is_popular ? 'pricing-card popular' : 'pricing-card'}
          key={pack.id}
          variants={{
            hidden: {
              opacity: 0,
              transform: 'translateY(20px) scale(0.8)',
            },
            show: {
              opacity: 1,
              transform: 'translateY(0) scale(1)',
              transition: {
                duration: 0.6,
                ease: 'backOut',
              },
            },

          }}
        >
          <div className="pricing-title">{pack.name}</div>
          <div className="pricing">
            {isGooglePlaySupported && playPrices[pack.plan_name] ? (
              ((pack.old_price > 0) ? (
                <>
                  <del className="text-danger">
                    {pack.old_price}
                    {' '}
                    {settings?.CURRENCY_SYMBOL}
                  </del>
                  <br />
                  {playPrices[pack.plan_name]}
                </>
              ) : (
                <>
                  {playPrices[pack.plan_name]}
                </>
              ))
            ) : (
              ((pack.old_price > 0) ? (
                <>
                  <del className="text-danger">
                    {pack.old_price}
                    {' '}
                    {settings?.CURRENCY_SYMBOL}
                  </del>
                  <br />
                  {pack.price}
                  {' '}
                  {settings?.CURRENCY_SYMBOL}
                </>
              ) : (
                <>
                  {pack.price}
                  {' '}
                  {settings?.CURRENCY_SYMBOL}
                </>
              ))

            )}
            <span className="small-text" />
          </div>
          <div className="text-center"><small>{pack.description}</small></div>
          <div className="pricing-body">
            <div className="mt-2">
              {isAuthenticated ? (
                ((pack.is_free || pack.price === 0) ? (
                  (user.bonus_quota_2024 ? (
                    <SuperButton onClick={() => handleShare()} className="btn btn-primary w-100" disabled>Odebrany</SuperButton>
                  ) : (
                    <SuperButton onClick={() => handleShare()} className="btn btn-primary w-100">Udostępnij</SuperButton>
                  ))
                ) : (
                  (isGooglePlaySupported ? (
                    <SuperButton onClick={() => handleGooglePaymentRequest(pack.plan_name)} className="btn btn-primary w-100" isLoading={isProcessingPayment}>Kup</SuperButton>
                  ) : (
                    <Link to={`../checkout/${pack.id}`} className="btn btn-primary d-block">Kup</Link>
                  ))
                ))
              ) : (
              // eslint-disable-next-line react/button-has-type
                <button onClick={() => open()} className="btn btn-primary w-100">
                  {pack.is_free || pack.price === 0 ? 'Zarejestruj się' : 'Kup'}
                </button>
              )}
            </div>
            <ul>
              <li>
                <TablerIcon icon={IconCircleCheck} className="text-success" />
                {' '}
                <b>{pack.pdfs ? pack.pdfs : 'Bez limitu'}</b>
                {' '}
                {(pack.pdfs < 5) ? ((pack.pdfs < 2) ? 'zdjęcie' : 'zdjęcia') : 'zdjęć'}
                {' '}
                {(pack.pdfs < 5) ? ((pack.pdfs < 2) ? 'zadania' : 'zadań') : 'zadań'}
              </li>
              <li>
                <TablerIcon icon={IconCircleCheck} className="text-success" />
                {' '}
                <b>{pack.questions ? pack.questions : 'Bez limitu'}</b>
                {' '}
                {(pack.questions < 5) ? ((pack.questions < 2) ? 'pytanie' : 'pytania') : 'pytań'}
                {' '}
                {(pack.questions < 5) ? ((pack.questions < 2) ? 'uzupełniające' : 'uzupełniające') : 'uzupełniających'}
              </li>
              {pack.features?.split('\n')?.map((feature, i) => (
                <li key={i}>
                  <TablerIcon icon={IconCircleCheck} className="text-success" />
                  {' '}
                  {feature}
                </li>
              ))}
            </ul>
          </div>

        </MotionItem>
      ))}

      <LoginRegisterModel
        title="Zaloguj się aby kontynuować"
        onLogin={() => close()}
      />
    </MotionDiv>
  );
}
